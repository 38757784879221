export const REQUEST_STATUS = {
  approved: {
    value: 'approved',
    label: 'Aprobada'
  },
  notSend: {
    value: 'notSend',
    label: 'No enviada'
  },
  pending: {
    value: 'pending',
    label: 'Pendiente'
  },
  rejected: {
    value: 'rejected',
    label: 'Rechazada'
  }
}
