// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import CardContainer from '@/components/ui/CardContainer'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import BakartaPayRequestForm from '../BakartaPayRequestForm'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Utils
import { cloneDeep } from 'lodash'

export default {
  name: 'BakartaPayByTabs',
  components: {
    CardContainer,
    VuetifyToolBar,
    VuetifyTabs
  },
  mixins: [addonsMixin],
  data() {
    return {
      items: [
        {
          label: 'Integración',
          component: BakartaPayRequestForm
        }
      ],
      currentTabs: []
    }
  },
  created() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    getEveryNeededData() {
      this.setCurrentTabs()
    },
    /**
     * Establecemos las "pestañas" que se mostrarán en el componente
     */
    setCurrentTabs() {
      this.currentTabs = cloneDeep(this.items)
    }
  }
}
