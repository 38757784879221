// Constants
import { ADDONS, COMPANY_TYPES_BILLING, REQUEST_EMAIL } from '@/constants'
import { REQUEST_STATUS } from '../../contants'
// Components
import CardContainerElement from '@/components/ui/CardContainerElement'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Services
import { sendAnySendinblueEmail } from '@/services/email'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Utils
import { get } from 'lodash'
import { humanizePlaceObject } from '@/filters'

export default {
  name: 'BakartaPayRequestForm',
  components: {
    CardContainerElement
  },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      billingData: null,
      processingRequest: false
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    /**
     * Valor actual de la petición
     */
    requestStatus() {
      const bakartaPayConfig = get(this.companyAddonsSetupByUser, ADDONS.bakartaPay, {})
      return bakartaPayConfig.requestStatus || REQUEST_STATUS.notSend.value
    },
    /**
     * ¿Fue enviada?
     */
    requestWasSended() {
      return this.requestStatus === REQUEST_STATUS.pending.value
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('company', ['updateConfigs']),
    /**
     * Lanzado cuando pulsamos sobre "Aceptar"
     */
    async handleRequestButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          await this.sendAndSaveRequest()
        },
        actionButtonText: 'Enviar',
        text: '¿Estas seguro que deseas enviar la solicitud para activar Bakarta PAY?',
        type: 'warning',
        visible: true
      })
    },

    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    async getEveryNeededData() {
      this.setBillingData()
    },
    /**
     * Establecemos los valores de facturación
     * de la compañía y que usaremos para la solicitud
     */
    setBillingData() {
      // Datos de la compañía
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})
      // Datos de contacto el establecimiento
      const contactConfig = get(this.placeAddonsSetupByUser, ADDONS.contact, {})

      // Datos de facturación
      this.billingData = {
        id: this.companyData.id || '-',
        name: this.companyData.name || '-',
        billingEmail: companyConfig.billingEmail || '-',
        billingCompanyType: COMPANY_TYPES_BILLING[companyConfig.billingCompanyType].label || '-',
        billingAddress: humanizePlaceObject(companyConfig.billingAddress),
        billingExtraAddress: companyConfig.billingExtraAddress || '-',
        cif: companyConfig.cif || '-',
        phone: contactConfig.phone1
      }
    },
    /**
     * Enviamos email y almacenamos el estado de la petición
     */
    async sendAndSaveRequest() {
      // Loading
      this.processingRequest = true
      try {
        const dataToSave = {
          id: this.companyData.id,
          addOns: [
            {
              id: ADDONS.bakartaPay,
              configFields: {
                requestStatus: REQUEST_STATUS.pending.value
              }
            }
          ]
        }
        // Enviamos email
        await sendAnySendinblueEmail({
          method: 'bakartaPayRequest',
          email: REQUEST_EMAIL,
          name: 'Sistema automatizado',
          customParams: this.billingData
        })

        // Actualizamos configuraciones
        await this.updateConfigs(dataToSave)

        // show info
        this.modifyAppAlert({
          text: 'La solicitud de petición de Bakarta PAY se realizó correctamente',
          type: 'info',
          visible: true
        })
      } catch (error) {
        // show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
